/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";

*, *:focus, *:hover {
    outline: none;
}

body, html {
    margin: 0;
    padding: 0;
    background: #ffffff;
    height: 100%;
    width: 100%;
    font-family: sans-serif, Helvetica, Arial;
}

body {
    display: flex;
    flex-flow: column;
}

router-outlet {
    position: fixed;
    bottom: 0;
}

app-path {
    height: 100%;
}

app-root {
    // width: 100%;
    // height: 100%;
    // display: inherit;
    flex-grow: 1;

    display: flex;
    justify-content: space-around;
    flex-flow: column;

    //Auth component centering the div.
    #auth-component-backdrop{
        align-items: center;
    }
    
    .cc-component-container {
        flex-grow: 1; /*Make the container full the column*/
        margin-top: 5em;
        //margin-bottom: 12em;

        &#step-details-container {
            margin-bottom: 5em;
        }

        &#cc-welcome {
            margin-top: 3em;
        }
        
        display: flex;
        flex-flow: column;
        justify-content: center;

        .cc-top {
            flex-flow: column;
            padding: 1em 1em;
        }

        .cc-content {
            flex-grow: 1; /*Let the content take priority*/
            
            display: flex;
            flex-flow: column;

            :first-child {
                flex-grow: 1;
            }
        }

        .cc-footer {
            padding-left: 1em;
            padding-right: 1em;
        }
        
    }
    
    #path-intro-backdrop {
        position: absolute;
        top: -35px;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        
    }
}

ngb-popover-window {
    min-width: 15em;
}


a {
    cursor: pointer;
}

strong, h2,h3,h4,h5,h6 {
    color: #170062ff;
}

.cc-tooltip {
    font-size: 1em;
    padding: 0.4em;
    color:  #869ab8;
    background: url('./assets/images/icons/info-icon.svg') no-repeat center;
}


// ROUNDED CORNER ELEMENTS
.btn, button, a, .auth-wrapper, .section-title, .cc-summary-last-survey, input, textarea, .modal-content,
.list-group-item {
    border-radius: 0.25em;
}
button.form-control-submit-button {
    border-radius: 0 0.5em 0.5em 0;
}

.dropdown-menu.show {
    padding-top: 0;
    padding-bottom: 0;

    .dropdown-item {
        padding-top: 0.5em;
        padding-bottom: 0.5em;

        white-space: pre-wrap;
        border-bottom: 1px solid #f4f4f4;        
    }
}


.section-title {
	display: inline-block;
	margin-bottom: 0.75rem;
	padding: 0.375rem 1.25rem;
	//border-radius: 20px;
	background-color: rgba(51, 94, 234, 0.1);
	color: #007bff;
	font-weight: 600;
	font-size: 0.75rem;
	line-height: 1.125rem;
}

/*================================================
    Step Details Modal Container
    FYI: This is taken out of the dom position as a result it will not impact the layout.s
*/
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 4em;
    z-index: 100000;

    .modal-content {
        //margin-top: 4em;
    }
}

/**=================================================
    THE TREE STYLING
**/
path.link {
    stroke: #335eea !important;
}

//The text on each d3 node circle
g.node div {
    color: white;
}

.node {
    cursor: pointer;
    letter-spacing: 1.5px;
    font-size: 13px;
}

.default-node {
    .ghostCircle {
        // fill: #170062;
        fill: #ffffff;
        stroke: #0f6fff;
        stroke-width: 1px;
        opacity: 1;

        //When you do a mouseover on the node
        &.over {

        }
    }
    .node {
        fill: #2937f0;
        stroke: #0f6fff; 
        
    }
}

.choosen, #start-0 {
    .ghostCircle {
        fill: #ffffff;
        stroke: #2937f0;
        stroke-width: 1;
        stroke-dasharray: 26px;
    }

    .node {
        fill: #0f6fff;
    }
}



/*====================================================
    ICONS
*/
.cc-icon {
    color: #fff !important;
    background-color: #335eea Imp !important;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #506690;
    outline: none;
    z-index: 100;

    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}
.close-icon:hover, .close-icon:focus {
    cursor: pointer;
    outline: none;
}


.step-details-content .row {
    margin-right: 0;
    margin-left: 0;
}



.circle {
    display: flex;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px #0000001a;
    background: #42ba96;
}

.pulse {
    animation: pulse-animation 2s infinite;
}


@keyframes pulse-animation {
    0% {
       box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}


/*******************
    The Path Navigation Buttons
**/
#path-navigation-backdrop {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 1em;
    background: transparent;
}


/**==========================
Captcha hide the badge
**/
.grecaptcha-badge {
    visibility: hidden;
}

/***==============================
    
**/
@media only screen and (max-width: 768px) {
    
    .row.cc-lower {
        margin-right: 0;
        margin-left: 0;
    }

    #path-navigation-backdrop {
        
        .row {
            padding-left: 5%;
            padding-right: 5%;
        }
    }

    /*The modal*/
    .modal-container .modal-content {
        margin-top: 0em;
    }

    app-root {
        .cc-component-container {
            margin-top: 1em;
        }

        //Override for decision wizard.
        .cc-component-container#decision-backdrop {
            margin-top: 6em;
        }
    }
    
    button.form-control-submit-button {
        border-radius: 0.5em;
    }
}
